import React, { FC, useRef } from 'react';
import { Portal } from '@reach/portal';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import { fadeInOut } from '@utils/motion/fade-in-out';
import { zoomOutIn } from '@utils/motion/zoom-out-in';
import { useUI } from '@contexts/ui.context';
import useOnClickOutside from '@utils/use-click-outside';
import CloseModalButton from '@components/close-modal-button';

type ModalProps = {
    open?: boolean;
    children?: React.ReactNode;
    onClose: () => void;
    rootClassName?: string;
    useBlurBackdrop?: boolean;
    containerClassName?: string;
    variant?: 'center' | 'bottom';
};
type DivElementRef = React.MutableRefObject<HTMLDivElement>;

const rootClasses = {
    center: 'p-4 md:p-5',
    bottom: 'p-5 pb-0',
};
const containerClasses = {
    center: 'h-auto max-h-full top-1/2 -translate-y-1/2',
    bottom: 'h-full max-h-70vh bottom-0',
};

const Modal: FC<ModalProps> = ({
    children,
    open,
    onClose,
    rootClassName,
    useBlurBackdrop,
    containerClassName,
    variant = 'center',
}) => {
    const { closeModal } = useUI();
    const modalRootRef = useRef() as DivElementRef;
    const modalInnerRef = useRef() as DivElementRef;
    useOnClickOutside(modalInnerRef, () => closeModal());
    return (
        <Portal>
            <AnimatePresence>
                {open && (
                    <motion.div
                        ref={modalRootRef}
                        key="modal"
                        initial="from"
                        animate="to"
                        exit="from"
                        variants={fadeInOut(0.25)}
                        className={cn(
                            'modal-root fixed bg-heading bg-opacity-70 inset-0 z-50',
                            useBlurBackdrop && 'backdrop-filter backdrop-blur-sm',
                            rootClasses[variant],
                            rootClassName
                        )}
                    >
                        <CloseModalButton onClick={onClose} />
                        <motion.div
                            initial="from"
                            animate="to"
                            exit="from"
                            variants={zoomOutIn()}
                            className="relative h-full mx-auto w-full"
                        >
                            <div
                                className={cn(
                                    'w-full md:w-auto absolute left-1/2 transform -translate-x-1/2 shadow-xl',
                                    containerClasses[variant],
                                    containerClassName
                                )}
                            >
                                <div
                                    ref={modalInnerRef}
                                    className="group overflow-y-auto h-full"
                                    style={{ maxHeight: 'calc(100vh - 140px)' }}
                                >
                                    <div className="absolute -bottom-10 left-0 right-0 h-[1px] bg-white bg-opacity-50 max-w-[280px] group-hover:max-w-full group-hover:bg-telesoft transition-all duration:1000" />
                                    {children}
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </Portal>
    );
};

export default Modal;
