import React from 'react';
import type { AppProps } from 'next/app';
import { useRef, ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ManagedUIContext } from '@contexts/ui.context';
import ManagedModal from '@components/common/modal/managed-modal';
import { Footer, MainMenu } from '@payload/types';
import { pageview } from '@lib/gtm';
import * as gtag from '@lib/gtag';
// const posthog = dynamic(() => require('posthog-js'))
// import { POSTHOG_ID } from '@lib/constants';

import '@styles/custom-menu-button.css';
import '@styles/globals.css';
import '@styles/scrollbar.css';
import '@styles/swiper.css';
import Script from 'next/script';

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
    header: MainMenu;
    footer: Footer;
};

const CustomApp = ({ Component, pageProps }: AppPropsWithLayout) => {
    const router = useRouter();
    const queryClientRef = useRef<any>();
    if (!queryClientRef.current) {
        queryClientRef.current = new QueryClient();
    }
    React.useEffect(() => {
        const handleRouteChange = (url: string) => {
            gtag.pageview(url);
            // if (POSTHOG_ID) {
            //   posthog.capture('$pageview');
            // }
            // };
            // if (POSTHOG_ID) {
            //   posthog.init(POSTHOG_ID, {
            //     api_host: 'https://app.posthog.com',
            //   });
        }
        router.events.on('routeChangeComplete', pageview);
        router.events.on('routeChangeComplete', handleRouteChange);
        router.events.on('hashChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', pageview);
            router.events.off('routeChangeComplete', handleRouteChange);
            router.events.off('hashChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        <>
            <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
            />
            <Script
                id="gtag-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
            gtag('config', 'AW-950423521');
          `,
                }}
            />
            <Script
                id="gtag-base"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', 'GTM-PFF834Q');
          `,
                }}
            />
            <QueryClientProvider client={queryClientRef.current}>
                <ManagedUIContext>
                    <Component {...pageProps} key={router.route} />
                    <ManagedModal />
                </ManagedUIContext>
                {/* <ReactQueryDevtools /> */}
            </QueryClientProvider>
        </>
    );
};

export default CustomApp;
